import React from "react";

export const StarMap = () => {
  return (
    <svg
      id="starmap"
      className="svg"
      width="100%"
      height="100%"
      viewBox="0 0 142 144"
      version="1.1"
    >
      <title>
        Star map: an interactive map of constellations made with React
      </title>
      <desc>Animated star map of Lyra, Sagitta, and Cygnus constellations</desc>
      <defs></defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-97.000000, -215.000000)">
          <g transform="translate(30.000000, 3.000000)">
            <g id="swan" transform="translate(67.000000, 212.000000)">
              <path
                stroke="#4e4e4e"
                d="M81.0720234 20.5106335 58.2248955 16.1290925 2.71877904 25.1515052 32.5876207 40.639477 68.7189312 45.0043543"
              ></path>
              <path
                stroke="#4e4e4e"
                d="M81.3469763 20.8241215 98.4888314 31.4285549 95.5726643 34.3124359"
              ></path>
              <path
                stroke="#4e4e4e"
                d="M99.0720649 32.3231933 129.1742 47.1071191 139.982765 48.9390969"
              ></path>
              <path
                stroke="#4e4e4e"
                d="M129.867831 46.5853335 121.678609 48.1788105 102.206945 55.8368736 69.1094893 44.6710781 63.8645544 76.7499578 60.3411997 86.6066026 58.846664 114.399758"
              ></path>
              <circle fill="#FFFFFF" cx="80.5040956" cy="20.5" r="3.5"></circle>
              <circle
                fill="#FFFFFF"
                cx="102.145594"
                cy="55.7670047"
                r="2.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="68.1455937"
                cy="44.7670047"
                r="2.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="33.1455937"
                cy="40.7670047"
                r="2.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="3.14559365"
                cy="25.7670047"
                r="2.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="59.1455937"
                cy="113.767005"
                r="2.5"
              ></circle>
              <circle fill="#FFFFFF" cx="58.3239779" cy="16" r="1.5"></circle>
              <circle
                fill="#FFFFFF"
                cx="121.645594"
                cy="47.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="129.645594"
                cy="46.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="139.645594"
                cy="48.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="95.6455937"
                cy="34.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="63.6455937"
                cy="76.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="59.6455937"
                cy="86.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="98.6455937"
                cy="31.3615187"
                r="1.5"
              ></circle>
              <g transform="translate(21.000000, 0.000000)" fill="#FFFFFF">
                <circle cx="46.3239779" cy="5" r="1.5"></circle>
                <circle cx="110.645594" cy="14.3615187" r="1.5"></circle>
                <circle cx="1.64559365" cy="10.3615187" r="1.5"></circle>
                <circle cx="16.6455937" cy="7.36151869" r="1.5"></circle>
                <circle cx="21.6455937" cy="2.36151869" r="1.5"></circle>
                <circle cx="22.6455937" cy="13.3615187" r="1.5"></circle>
              </g>
              <g id="sagitta" transform="translate(10.000000, 121.000000)">
                <path
                  stroke="#4e4e4e"
                  d="M2.49428096 2.15867166 7.71763614 13.2578489 10.2315794 21.3589362"
                ></path>
                <path
                  d="M8.49317588,13.7103225 L13.6984321,21.2268139"
                  stroke="#4e4e4e"
                ></path>
                <circle
                  fill="#FFFFFF"
                  cx="13.8913376"
                  cy="21.3615187"
                  r="1.5"
                ></circle>
                <circle
                  fill="#FFFFFF"
                  cx="9.64559365"
                  cy="21.3615187"
                  r="1.5"
                ></circle>
                <circle
                  fill="#FFFFFF"
                  cx="2.2063125"
                  cy="2.36151869"
                  r="1.5"
                ></circle>
                <circle
                  fill="#FFFFFF"
                  cx="8.14559365"
                  cy="13.8615187"
                  r="1.5"
                ></circle>
              </g>
              <g id="lyra" transform="translate(90.000000, 102.000000)">
                <path
                  stroke="#4e4e4e"
                  d="M32.5040874 6.85830055 23.2949531 6.85830055 16.5777023 3.65422288 3.63233628 13.6807076 10.3161283 16.9150575 23.2949531 7.2846402"
                ></path>
                <circle fill="#FFFFFF" cx="3.14559365" cy="14" r="2.5"></circle>
                <circle
                  id="Star"
                  fill="#FFFFFF"
                  cx="32"
                  cy="8"
                  r="3"
                  strokeWidth="2"
                  strokeDasharray="1"
                ></circle>
                <circle
                  fill="#FFFFFF"
                  cx="23.6455937"
                  cy="6.36151869"
                  r="1.5"
                ></circle>
                <circle
                  fill="#FFFFFF"
                  cx="16.6455937"
                  cy="3.36151869"
                  r="1.5"
                ></circle>
                <circle
                  fill="#FFFFFF"
                  cx="10.6455937"
                  cy="16.3615187"
                  r="1.5"
                ></circle>
              </g>
              <circle
                fill="#FFFFFF"
                cx="29.6455937"
                cy="111.361519"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="50.6455937"
                cy="126.361519"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="122.645594"
                cy="84.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="100.645594"
                cy="88.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="95.6455937"
                cy="90.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="37.6455937"
                cy="64.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="27.6455937"
                cy="64.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="19.6455937"
                cy="49.3615187"
                r="1.5"
              ></circle>
              <circle
                fill="#FFFFFF"
                cx="125.645594"
                cy="123.361519"
                r="1.5"
              ></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
