import "./App.css";
import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Projects from "./Pages/Projects";

function App() {
  // log your silly facts to the console.
  littleLogger();

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div
      className="App background"
      onMouseMove={handleMouseMove}
      style={{
        background: `radial-gradient(circle at ${mousePosition.x}px ${mousePosition.y}px, ivory,   #f5b7b1  )`,
      }}
    >
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
    </div>
  );
}

const littleLogger = () => {
  const randomFacts = [
    "While cold-blooded, some fish have an ability to heat certain parts of their bodies to protect themselves against the cold of the ocean. Tuna, for example, can heat their muscles to have edge in pursuing prey in cold water, and swordfish will heat their eyes to see better while hunting. Separately, there is at least one fish which is truly not cold-blooded: the opah, or moonfish. https://oceanservice.noaa.gov/facts/cold-blooded.html https://www.newscientist.com/article/dn6861-swordfish-heat-their-eyes-for-the-hunt/.",
    "Jupiter's fifth moon, Io, is known for violent volcanic activity. The immense gravity of Jupiter is effectively ripping Io apart constantly, causing the seismic activity -- and ionization of the material being torn away causes immense aurorae across Jupiter. https://www.space.com/16419-io-facts-about-jupiters-volcanic-moon.html",
    "Crying when emotional is beneficial in many ways -- in one of the most tangible ways, the human body uses tears (the type generated by emotion) to excrete excess stress hormones, such as cortisol. https://www.psychologytoday.com/us/blog/emotional-freedom/201007/the-health-benefits-tears",
    "Insects don't use lungs to breathe, or distribute oxygen through their bodies using a circulatory system the way that human bodies do. Instead, they have openings along their bodies called 'spiracles' (from the same Latin root as words like 'inspiration') which let air into the body where it bathes the tissues with oxygen. https://www.thoughtco.com/how-do-insects-breathe-1968478",
    "The Saga of Saint Olaf -- a Viking saga written in the 13th century -- mentions a mythical sunstone which allowed the vikings to orient themselves relative to the sun, even if it was hidden behind clouds, or had already set below the horizon. Further examination of the wreck of Alderney, however (a 16th century Elizabethan warship originally discovered by a fisherman in the 1970s) identified a block of stone among other navigational items found with the wreck. The stone was specifically composed of Icelandic Spar, or calcite crystal -- which, due to the formation of the crystals, will reveal within a few degrees whether you are looking through it due east-west. https://www.livescience.com/27696-viking-sunstone-shipwreck.html",
    "Botanically speaking, avocados are actually very large berries, with a single seed at the center. While this may seem like a scientific technicality, it points to the origin of how avocados proliferated and spread. Like many berries, it spread by being a food source for animals, which ate the berry and then passed the seeds out in their waste. One such animal was so large that the avocado really would seem just like a tiny berry to it -- the Megatherium or giant ground sloth. The size of a UPS truck, these sloths roamed South America through the end of the Pleistocene era (about 12000 years ago.) https://www.smithsonianmag.com/arts-culture/why-the-avocado-should-have-gone-the-way-of-the-dodo-4976527/",
  ];
  const random = randomFacts[Math.floor(Math.random() * randomFacts.length)];
  console.log(
    "%cDid you know?" +
      "%c\n\n" +
      random +
      "\n\nRefresh the page to get a new fact!",
    "border-bottom: 2px solid gold",
    "border-bottom: none"
  );
};

export default App;
