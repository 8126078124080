import React from "react";

export const Cheese = () => {
  return (
    <svg
      id="sparkles"
      className="svg"
      width="180px"
      height="180px"
      viewBox="0 0 196 201"
      version="1.1"
    >
      <title>Mousetrap: Cursor tracking experiments</title>
      <desc>A sparkling cheese animation</desc>
      <g
        className="cheese"
        transform="translate(0.825736, -0.658050)"
        stroke="#4e4e4e"
        strokeWidth="1"
        fill="#fefefe"
        fillRule="evenodd"
      >
        <path
          className="rind"
          d="M140.53716,155.674043 C166.627074,150.53006 180.874432,142.645546 183.279234,132.0205 C183.279234,112.898607 183.279234,89.48601 183.279234,61.0543107 C172.180863,33.5734235 33.7819873,29.4485314 18.8544183,48.2757285"
          fillRule="nonzero"
        ></path>
        <path
          d="M98.42355,48.8150502 L98.42355,133.1416 C121.454239,146.324621 135.388137,153.846556 140.225244,155.707405 C140.225244,139.556569 140.107136,81.1221493 140.225244,68.8847891 C130.506188,63.6928895 116.57229,57.0029765 98.42355,48.8150502 Z"
          fillRule="nonzero"
        ></path>
        <path
          d="M97.962231,133.445916 C60.1919896,133.617667 33.9639126,133.617667 19.2779999,133.445916 C19.2779999,115.109879 19.9741703,75.5914275 19.2779999,48.0902908 C30.4208537,48.0902908 56.6489307,48.4236242 97.962231,49.0902908"
          fillRule="nonzero"
        ></path>
        <circle fillRule="nonzero" cx="63.5" cy="71.5" r="2.5"></circle>
        <circle fillRule="nonzero" cx="41.5" cy="94" r="2.5"></circle>
        <circle fillRule="nonzero" cx="48" cy="68" r="1"></circle>
        <circle fillRule="nonzero" cx="53" cy="62" r="1"></circle>
        <circle fillRule="nonzero" cx="76" cy="95" r="1"></circle>
        <circle fillRule="nonzero" cx="83" cy="102" r="1"></circle>
        <polyline
          className="rind"
          fillRule="nonzero"
          points="79.963041 175.047964 0.0870315 150.132867 0.0870315 125.291426 28.2875717 104.3208"
        ></polyline>
        <polygon
          fillRule="nonzero"
          points="80.145242 174.933603 2.5312463 126.04788 31.2780504 104.613485 107.564642 146.826102 90.926149 162.859841 85.479484 162.859841 85.479484 170.595655"
        ></polygon>
        <circle fillRule="nonzero" cx="41" cy="137" r="3"></circle>
        <circle fillRule="nonzero" cx="107.564642" cy="121" r="3"></circle>
        <circle fillRule="nonzero" cx="62" cy="134" r="4"></circle>
        <circle fillRule="nonzero" cx="69" cy="153" r="4"></circle>
        <circle
          fillRule="nonzero"
          cx="124.433924"
          cy="89.037324"
          r="4"
        ></circle>
        <circle fillRule="nonzero" cx="35" cy="120" r="2"></circle>
        <polygon
          className="sparkle tiny-diamond one"
          points="191.959409 87.0373244 189.442594 89.7549639 191.959409 92.0373244 194.442594 89.7549639"
        ></polygon>
        <polygon
          className="sparkle tiny-diamond two"
          points="11.0168157 80.0373244 8.5 82.7549634 11.0168157 85.0373244 13.5 82.7549634"
        ></polygon>
        <polygon
          className="sparkle tiny-diamond three"
          points="129.950742 172.933603 127.433924 175.651243 129.950742 177.933603 132.433924 175.651243"
        ></polygon>
        <polygon
          className="sparkle tiny-diamond four"
          points="33.5168157 16.3899994 31 19.1076384 33.5168157 21.3899994 36 19.1076384"
        ></polygon>
        <polygon
          className="sparkle tiny-diamond five"
          points="41.5067263 198.426172 40.5 199.513227 41.5067263 200.426172 42.5 199.513227"
        ></polygon>
        <polygon
          className="sparkle tiny-diamond six"
          points="71.5168157 29 69 31.717639 71.5168157 34 74 31.717639"
        ></polygon>
        <polygon
          className="sparkle tiny-diamond seven"
          points="158.314714 31 155.797899 33.717639 158.314714 36 160.797899 33.717639"
        ></polygon>
        <polygon
          className="sparkle medium-diamond one"
          points="7.52690496 57 3.5 61.3482222 7.52690496 65 11.5 61.3482222"
        ></polygon>
        <polygon
          className="sparkle medium-diamond two"
          points="88.0269056 8.3899994 84 12.7382216 88.0269056 16.3899994 92 12.7382216"
        ></polygon>
        <polygon
          className="sparkle medium-diamond three"
          points="105.593732 175.047964 101.566826 179.396186 105.593732 183.047964 109.566826 179.396186"
        ></polygon>
        <g
          className="tiny-cross sparkle one"
          transform="translate(101.066826, 6.450846)"
        >
          <path d="M1.61013305,0.327847453 L1.61013305,3.31931288"></path>
          <path d="M0.223441047,1.83654414 L2.89655788,1.83654414"></path>
        </g>
        <g
          className="tiny-cross sparkle two"
          transform="translate(4.000000, 0.450846)"
        >
          <path d="M2.26403445,0.729264467 L2.26403445,3.72072989"></path>
          <path d="M0.877342453,2.23796115 L3.55045929,2.23796115"></path>
        </g>
        <polygon
          className="sparkle tiny-diamond eight"
          points="124.950742 27 122.433924 29.717639 124.950742 32 127.433924 29.717639"
        ></polygon>
      </g>
    </svg>
  );
};
